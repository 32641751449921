
.cart {
  z-index: 100;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 430px;
  //background: rgb(243, 243, 247);
  background:#fff;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.5, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0.5, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0.5, 1, 0.5, 1);
  -webkit-transform: translateX(530px);
  -ms-transform: translateX(530px);
  transform: translateX(530px);

  &__body {
    &.ordered {
      min-height: 100vh;
      margin:auto 0;
      //flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__order {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    &-message {
      font-size: 20px;
      font-weight: 500;
      span {
        font-weight: 900;
      }
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
  @media (max-width: 500px) {
    width: 100vw;
  }
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cart.open {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.cart__body {
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.cart__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto 0;
}

.cart__close {
  position: absolute;
  top: 50%;
  -webkit-transition: -webkit-transform 500ms ease 0s;
  transition: -webkit-transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s, -webkit-transform 500ms ease 0s;
  left: -50px;
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
}

.cart__overlay {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.64);
  -webkit-transition: opacity 300ms ease-out 0s;
  -o-transition: opacity 300ms ease-out 0s;
  transition: opacity 300ms ease-out 0s;
  opacity: 0;
  z-index: 5;
}

.cart__overlay.open {
  opacity: 1;
}

.cart__image {
  margin: 0px 0px 30px 0px;
}

.cart__title {
  margin: 0px 0px 30px 0px;
  font-size: 1.5em;
  font-weight: 700;
}

.cart__description {
  text-align: center;
  margin: 0px 0px 30px 0px;
  line-height: 1.4;
}

.cart__button {
  height: 40px;
  padding: 8px 20px;
}

.order__label {
  padding: 0px 16px;
  margin-top: 24px;
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 28px;
}

.item-order {
  background-color: #fff;
  padding: 12px 16px;
  margin-bottom: 8px;
}

.item-order__top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid rgb(226, 226, 233);
  padding-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.item-order__close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.item-order__close img {
  width: 100%;
  height: 100%;
}

.item-order__image-ibg {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  padding: 0 0 14% 0;
  img {
    object-fit: contain
  }
}

.item-order__description {
  margin-left: 10px;
  height: 100%;
}

.item-order__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 0px 4px;
}

.item-order__options {
  font-size: 12px;
  line-height: 16px;
  color: rgb(92, 99, 112);
  margin: 0px;
  &.underline {
    &:first-line {
      font-weight: 700;
    }
  }
}

.item-order__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 12px;
}

.item-order__price {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.item-order__quantity {
  position: relative;
  min-width: 96px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(243, 243, 247);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 2em;
  font-size: 20px;
}

.quantity__center {
  padding: 5px;
  opacity: 0.5;
}

.quantity__button {
  padding: 3px;
  opacity: 0.5;
  font-size: 20px;
}

.add-order {
  padding: 0px;
  background: rgb(243, 243, 247);
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 30px;
}

.add-order__title {
  margin: 16px 16px 0px;
  font-family: Dodo, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
}

.add-order__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
}

.add-order__item {
  background-color: #fff;
  padding: 0px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2em;
  cursor: pointer;
  -webkit-box-shadow: rgba(6, 5, 50, 0.1) 0px 6px 20px;
  box-shadow: rgba(6, 5, 50, 0.1) 0px 6px 20px;
  &.select {
    display: none;
  }
}

.add-order__image {
  margin-right: 10px;
  width: 92px;
  height: 92px;
  border-radius: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-order__image img {
  width: 80%;
  height: 80%;
}

.add-order__text {
  font-size: 14px;
  line-height: 18px;
}

.add-order__price {
  margin-top: 4px;
  font-weight: 500;
}

.end-order {
  padding: 0 24px;
  background-color: #fff;
}

.end-order__promo {
  width: 100%;
  position: relative;
  padding: 16px 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.end-order__promo input {
  padding: 0px 90px 0px 0px;
  border: none;
  border-radius: 0px;
  height: 20px;
  outline: none;
}

.end-order__promo::after {
  content: " ";
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom: 1px solid rgb(226, 226, 233);
}

.end-order__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 12px 54px 12px 48px;
  margin-bottom: 20px;
  line-height: 24px;
  height: 48px;
}

.quantity-end-order {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  border-bottom: 1px solid rgb(226, 226, 233);
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding: 16px 0px 24px;
}

.quantity-end-order__items {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.quantity-end-order__price-dodo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.dodo-order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  &__popup {
    min-height: 45px;
    line-height: 150%;
    padding: 14px;
    top: -103px;
    right: -110px;

    &:after {
      top: 117%;
      right: 31.5%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

.dodo-order__text {
  margin-right: 5px;
}

.dodo-order__info {
  height: 16.8px;
  width: 16.8px;
  opacity: 0.5;
  will-change: transform;
  -webkit-transition: all 0.15s ease 0s;
  -o-transition: all 0.15s ease 0s;
  transition: all 0.15s ease 0s;
}

.dodo-order__info img {
  width: 100%;
  height: 100%;
}

.dodo-order__image {
  width: 14px;
  height: 14px;
}

.dodo-order__image img {
  width: 100%;
  height: 100%;
}

.sum-end-order {
  padding: 8px 0px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

@media (max-width: 31.25em) {

  .cart__close {
    position: fixed;
    left: auto;
    top: 20px;
    right: 20px;
    background-color: #ff6900;
    border-radius: 10px;
    padding: 5px;
  }
}

.add-order__items {
  //position: relative;
}

.popup-order {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  z-index: 2;
  padding: 0px 24px 40px;
  border-radius: 9px 9px 0px 0px;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.5, 1, 0.5, 1);
  transform: translateY(100%);
  z-index: 1010;
  &.active {
    transform: translateY(0px);
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 48px 0px 8px;
  }
  &__items {
    width: 100%;
  }
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    width: 25px;
    height: 25px;
  }
  &__price {
   flex: 0 0 96px;
    min-width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
  &__name {
    flex: 1 1 100%;
  }
}
