
.pizza__title {
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 52px;
}

.pizza__items {
  display: -ms-grid;
  display: grid;
  row-gap: 60px;
  -webkit-column-gap: 35px;
  -moz-column-gap: 35px;
  column-gap: 35px;
  grid-template-columns: repeat(auto-fill, 292px);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pizza__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  height: 100%;
}

.item-pizza {
  width: 292px;
  display: flex;
  flex-direction: column;
}

.item-pizza__image {
  width: 278px;
  height: 278px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: -webkit-transform 150ms ease-out 0s;
  transition: -webkit-transform 150ms ease-out 0s;
  -o-transition: transform 150ms ease-out 0s;
  transition: transform 150ms ease-out 0s;
  transition: transform 150ms ease-out 0s, -webkit-transform 150ms ease-out 0s;
  position: relative;
}

.item-pizza__image img {
  width: 100%;
  height: 100%;
}

.item-pizza__title {
  margin: 8px 0px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.item-pizza__description {
  color: rgb(92, 99, 112);
  line-height: 20px;
  line-height: 150%;
  margin: 0px 0px 30px 0px;
  flex: 1 1 auto;
}

.item-pizza__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.item-pizza__price {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-right: 4px;
  font-size: 20px;
}

.description-popup__ingridients {
  margin-bottom: 15px;
  @media (max-width: 479.98px){
    margin-bottom: 30px;
  }
}

.description-popup__ingridients-button {
  margin-right: 5px;
  margin-bottom:5px;
  svg {
    position: relative;
    top: 3px;
    right: -1px;
  }
  &.deleted {
    text-decoration: line-through;
  }
}