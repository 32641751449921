
.pizza__overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.64);
  -webkit-transition: opacity 300ms ease-out 0s;
  -o-transition: opacity 300ms ease-out 0s;
  transition: opacity 300ms ease-out 0s;
  opacity: 0;
  z-index: 10;

  &.open {
    opacity: 1;
  }
}

.popup-pizza {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 924px;
  height: 610px;
  border-radius: 2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease 0s;
  @media (max-width: 991.98px) {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    width: 97vw;
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &__send {
    position: fixed;
    left: 70vw;
    top: 10vh;
    background-color: #383637;
    z-index: 10;
    border-radius: 15px;
    padding: 15px;
    opacity: 0;
    transition: all 0.3s ease 0s;
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
      -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both;
      animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 3s both;
    }
  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

.popup-pizza__close {
  position: absolute;
  right: -45px;
  top: 10px;
  width: 25px;
  height: 25px;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  background-color: transparent;
  will-change: transform;
  @media (max-width: 991.98px){
    right: 30px;
    top: 30px;
    width: 50px;
    height: 50px;
    background-color: #ff6900;
    z-index: 1044444;
    padding: 10px;
    border-radius: 18px;
  }
  @media (max-width: 479.98px){
    right: 10px;
    top: 10px;
  }
}

.popup-pizza__close img {
  width: 100%;
  height: 100%;
}

.popup-pizza__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 57%;
  flex: 0 1 57%;
  position: relative;
  @media (max-width: 991.98px) {
    // flex: 0 1 300px;
    min-height: 200px;

    //margin-bottom: 50px;
  }
}

.popup-pizza__circle-med {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 382px;
  height: 382px;
  z-index: 3;
  @media (max-width: 991.98px) {
    display: none;
  }
}

.popup-pizza__circle-med img {
  width: 100%;
  height: 100%;
}

.popup-pizza__circle-big {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 450px;
  height: 450px;
  z-index: 3;
  @media (max-width: 991.98px) {
    display: none;
  }
}

.popup-pizza__circle-big img {
  width: 100%;
  height: 100%;
}

.popup-pizza__pizza-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.3s ease 0s;
  -webkit-transform: translate(-50%, -50%) translatey(10px) translateX(10px);
  -ms-transform: translate(-50%, -50%) translatey(10px) translateX(10px);
  transform: translate(-50%, -50%) translatey(10px) translateX(10px);

}

.popup-pizza__pizza-image img {
  width: 100%;
  height: 100%;
  border-radius: 20%;
}

.popup-pizza__description-button {
  height: 48px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 20px 0px 0px;
  @media (max-width: 991.98px){
    display: inline-block;
    width: 350px;
    margin: 0 auto;
  }
  @media (max-width: 479.98px){
    margin: 20px auto;
    width: 300px;
  }
}

.description-popup {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 43%;
  flex: 0 1 43%;
  background: rgb(252, 252, 252);
  border-bottom-right-radius: 2em;
  border-top-right-radius: 2em;
  padding: 30px;
  padding-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (max-width: 479.98px){
    padding: 0;
    //overflow-x: hidden;
  }
}

.description-popup__container {
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 25px;
  @media (max-width: 991.98px){
    width:90vw;
  }
  @media (max-width: 479.98px){
    overflow-x: hidden;
  }
}

.description-popup__container::-webkit-scrollbar {
  width: 8px;
}

.description-popup__container::-webkit-scrollbar-track {
  background-color: transparent;
}

.description-popup__container::-webkit-scrollbar-thumb {
  -webkit-transition: opacity 0.4s ease-out 0s;
  transition: opacity 0.4s ease-out 0s;
  background-color: rgba(57, 57, 57, 0.15);
  border-radius: 4px;
  cursor: pointer;
}

.description-popup__title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.description-popup__label {
  margin: 0px 0px 7px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.description-popup__subtitle {
  color: rgb(92, 99, 112);
  line-height: 20px;
  margin: 0px 0px 10px 0px;
}

.description-popup__sizes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgb(243, 243, 247);
  height: 32px;
  border-radius: 2em;
  padding: 5px 30px;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin: 0px 0px 10px 0px;
  @media (max-width: 479.98px){
    //width: 300px;
    margin: 0 auto 30px;
    padding: 5px;
  }
}

.description-popup__size {
  cursor: pointer;
  font-size: 12px;
  line-height: 32px;
  //flex: 0 1 33.333%;
  //display: flex;
  //justify-content: center;
  @media (max-width: 991.98px){
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 3em;
  }
}

.description-popup__size span {
  position: relative;
  z-index: 1;
}

.description-popup__size-active {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 33.3333%;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: width, -webkit-transform 200ms ease-out 0s;
  transition: width, -webkit-transform 200ms ease-out 0s;
  -o-transition: transform 200ms ease-out 0s, width;
  transition: transform 200ms ease-out 0s, width;
  transition: transform 200ms ease-out 0s, width, -webkit-transform 200ms ease-out 0s;
  padding: 2px;
}

.size0 {
  -webkit-transform: translateX(calc(0%));
  -ms-transform: translateX(calc(0%));
  transform: translateX(calc(0%));
}

.size1 {
  -webkit-transform: translateX(calc(100%));
  -ms-transform: translateX(calc(100%));
  transform: translateX(calc(100%));
}

.size2 {
  -webkit-transform: translateX(calc(200%));
  -ms-transform: translateX(calc(200%));
  transform: translateX(calc(200%));
}


.description-popup__size-active::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 9999px;
  -webkit-box-shadow: rgba(6, 5, 50, 0.19) 0px 6px 20px;
  box-shadow: rgba(6, 5, 50, 0.19) 0px 6px 20px;
}

.description-popup__types {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgb(243, 243, 247);
  height: 32px;
  border-radius: 2em;
  padding: 5px 0px;
  position: relative;
  margin: 0px 0px 20px 0px;
  @media (max-width: 479.98px){
    width: 280px;
    margin: 0 auto 30px;
  }
}

.description-popup__type {
  cursor: pointer;
  font-size: 12px;
  line-height: 32px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 2em;
}

.description-popup__type span {
  position: relative;
  z-index: 1;
}

.description-popup__type-active {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: width, -webkit-transform 200ms ease-out 0s;
  transition: width, -webkit-transform 200ms ease-out 0s;
  -o-transition: transform 200ms ease-out 0s, width;
  transition: transform 200ms ease-out 0s, width;
  transition: transform 200ms ease-out 0s, width, -webkit-transform 200ms ease-out 0s;
  padding: 2px;
}

.description-popup__type-active::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 9999px;
  -webkit-box-shadow: rgba(6, 5, 50, 0.19) 0px 6px 20px;
  box-shadow: rgba(6, 5, 50, 0.19) 0px 6px 20px;
}

.add-description-popup {
  @media (max-width: 991.98px){
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.add-description-popup__section-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  @media (max-width: 991.98px){
    margin-bottom: 30px;
  }
}

.add-description-popup__select {
  opacity: 0;
  transition: all 0.15s ease 0s;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  z-index: 1;

  &.select {
    opacity: 1;
  }
}

.add-description-popup__items {
  display: -ms-grid;
  display: grid;
  gap: 8px;
  -ms-grid-columns: (106px) [3];
  grid-template-columns: repeat(3, 106px);
  @media (max-width: 479.98px){
    grid-template-columns: repeat(2, 106px);
  }
}

.add-description-popup__item {
  height: 166px;
  position: relative;
  padding: 8px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-radius: 12px;
  -webkit-box-shadow: rgba(6, 5, 50, 0.12) 0px 4px 20px;
  box-shadow: rgba(6, 5, 50, 0.12) 0px 4px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-transition: border, -webkit-box-shadow 150ms ease-out 0s;
  transition: border, -webkit-box-shadow 150ms ease-out 0s;
  -o-transition: box-shadow 150ms ease-out 0s, border;
  transition: box-shadow 150ms ease-out 0s, border;
  transition: box-shadow 150ms ease-out 0s, border, -webkit-box-shadow 150ms ease-out 0s;


  &.selected {
    box-shadow: rgb(6 5 50 / 12%) 0px 0px 0px;
    border: 1px solid rgb(255, 105, 0);
  }
}

.add-description-popup__image-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 8px;
  left: 8px;
  width: 90px;
  height: 90px;
}

.add-description-popup__image-ibg img {
  width: 100%;
  height: 100%;
}

.add-description-popup__title {
  font-size: 12px;
  line-height: 16px;
  margin-top: 93px;
}

.add-description-popup__price {
  margin-top: 9px;
  font-size: 16px;
  line-height: 20px;
}


.type0 {
  -webkit-transform: translateX(calc(0%));
  -ms-transform: translateX(calc(0%));
  transform: translateX(calc(0%));
}

.type1 {
  -webkit-transform: translateX(calc(100%));
  -ms-transform: translateX(calc(100%));
  transform: translateX(calc(100%));
}

.image0 {
  width: 332px;
  height: 332px;
  @media (max-width: 991.98px) {
    height: 110px;
    width: 110px;
  }
}

.image1 {
  width: 404px;
  height: 404px;
  @media (max-width: 991.98px) {
    height: 134px;
    width: 134px;
  }
}

.image2 {
  width: 476px;
  height: 476px;
  @media (max-width: 991.98px) {
    height: 158px;
    width: 158px;
  }
}

.info-popup {
  position: absolute;
  width: 250px;
  margin-top: 0px;
  top: 50px;
  right: -12px;
  left: initial;
  min-height: 45px;
  padding: 12px;
  border-radius: 10px;
  background-color: rgb(55, 53, 53);
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  visibility: hidden;

  &.open {
    opacity: 1;
    z-index: 5;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    top: -30px;
    //z-index: 1;
    right: 4px;
    border: 20px solid transparent;
    border-bottom: 20px solid rgb(55, 53, 53);
  }

  &__title {
    color: rgb(255, 255, 255);
    opacity: 0.5;
    margin: 0px 0px 12px;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

