@import url(https://fonts.googleapis.com/css?family=Roboto:300,regular,500,600,700&display=swap);

@charset "UTF-8";

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 320px;

}

body {
  color: #000;
  line-height: 1;
  font-family: "Roboto";
  font-size: 0.875rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: "Roboto";
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

.lock body {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

.wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.wrapper > main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.wrapper > * {
  min-width: 0;
}


[class*=__container] {
  max-width: 80rem;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 0.9375rem;
}

[class*=-ibg] {
  position: relative;
}

[class*=-ibg] img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

[class*=-ibg_contain] img {
  -o-object-fit: contain;
  object-fit: contain;
}

.icon-menu {
  display: none;
}

.icon-menu span {
  position: absolute;
  width: 2.5rem;
  height: 0.25rem;
  background: rgb(255, 105, 0);
  display: block;
  border-radius: 0.25rem;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.icon-menu span:nth-child(1) {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  width: 1.5625rem;
  left: 0.9375rem;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.icon-menu {
  &.menuOpen {
    span:nth-child(1) {
      -webkit-transform: translateY(0) rotate(45deg);
      -ms-transform: translateY(0) rotate(45deg);
      transform: translateY(0) rotate(45deg);
      width: 2.5rem;
      -webkit-transition-delay: 0.125s;
      -o-transition-delay: 0.125s;
      transition-delay: 0.125s;
    }
  }
}

.icon-menu span:nth-child(3) {
  -webkit-transform: translateY(15px);
  -ms-transform: translateY(15px);
  transform: translateY(15px);
  width: 0.9375rem;
  left: 0.9375rem;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.icon-menu {
  &.menuOpen {
    span:nth-child(3) {
      transform: translateY(0) rotate(315deg);
      width: 40px;
      transition-delay: 0.25s;
    }
  }
}

.icon-menu .menuOpen span:nth-child(3) {
  -webkit-transform: translateY(0) rotate(315deg);
  -ms-transform: translateY(0) rotate(315deg);
  transform: translateY(0) rotate(315deg);
  width: 2.5rem;
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.icon-menu {
  &.menuOpen {
    span:nth-child(2) {
      -webkit-transform: translateX(60px);
      -ms-transform: translateX(60px);
      transform: translateX(60px);
      -webkit-transition-delay: 0;
      -o-transition-delay: 0;
      transition-delay: 0;
    }
  }
}

.header__clone {
  background-color: #FF6900;
  height: 50px;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header__top {
  min-height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.header__logo {
  //width: 250px;
  //height: 46px;
  font-size: 40px;
  margin: 0px 40px 0px 0px;
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    margin-left: 20px;
    @media (max-width: 479.98px) {
      margin-left: 0px;
    }
  }
}

.header__delivery {
  font-size: 18px;
  line-height: 1.2;
}

.header__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &.active {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    .header__container-bottom {
      min-height: 58px;
      align-items: center;
    }

    .menu__header {
      transform: translate(0px, 0px);
    }
  }
}

.menu__header {
  display: flex;
  align-items: center;
  transform: translate(-52px, 0px);
  transition: all 0.3s ease 0s;
}

.header__dodo {
  width: 36px;
  height: 36px;
  //transform: scaleX(-1);
  img {
    width: 100%;
    height: 100%;
  }
}


.header__container-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  @media (max-width: 991.98px) {
    justify-content: end;
  }
}


.header__cart {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 15px 25px !important;

  &-number {
    opacity: 1;
    visibility: visible;

    &.active {
      opacity: 0;
      visibility: hidden;
    }
  }

  &-title {
    position: relative;

    &:after {
      content: '';
      background-color: #fff;
      width: 1px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0.5;
    }
  }

  &-image {
    width: 20px;
    height: 20px;
    transform: translate(10px, 4px);
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;

    &.active {
      transform: translate(20px, 4px);
      opacity: 1;
      visibility: visible;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }


}

.delivery-header {
  font-weight: 400;
}

.delivery-header__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.delivery-header__delivery {
  margin: 0px 7px 0px 0px;
}

.delivery-header__city {
  color: rgb(255, 105, 0);
}

.delivery-header__time {
  margin: 0px 15px 0px 0px;
  position: relative;
}

.delivery-header__time::after {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  right: -9px;
  top: 8px;
}

.delivery-header__estimation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.delivery-header__star {
  position: relative;
  top: 1px;
}

.delivery-header__star img {
  width: 100%;
  height: 100%;
}

.dodocoins-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0px 40px 0px 0px;
  cursor: pointer;
}

.dodocoins-header__image {
  -webkit-transition: -webkit-transform 500ms ease 0s;
  transition: -webkit-transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s, -webkit-transform 500ms ease 0s;
  margin: 0px 0px 8px 0px;
  will-change: transform;
}

.dodocoins-header__title {
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  font-weight: 500;
}

.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu__item {
  padding: 10px;
}

.menu__item:not(:first-child) {
  margin-left: 20px;
}

.menu__link {
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  white-space: nowrap;
}

.button {
  padding: 8px 16px;
  line-height: 16px;
  background-color: rgb(243, 243, 247);
  color: rgb(92, 99, 112);
  border-radius: 2em;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
  position: relative;
}

.button__orange {
  background-color: rgb(255, 105, 0);
  color: #fff;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  padding: 8px 20px;
}

.button__check {
  background-color: rgb(255, 240, 230);
  color: rgb(209, 87, 0);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 1.5em;
  height: 40px;
  min-width: 120px;
}

.button__arrow {
  position: absolute;
  right: 8px;
  top: calc(50% - 13px);
  height: 24px;
}

.button__arrow path {
  stroke: rgb(255, 255, 255);
}

@media (max-width: 35em) {
  .header__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 81.875em) {
  .links-footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .download-footer {
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .download-footer__items {
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }
}

.page__often {
  padding: 50px 0;
}

.page__pizza {
  padding: 40px 0;
}


@media (max-width: 61.99875em) {
  .icon-menu {
    display: block;
    position: relative;
    width: 4.375rem;
    height: 4.375rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.375rem;
    flex: 0 0 4.375rem;
    background: transparent;
    border-radius: 0.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 6;
    cursor: pointer;
    overflow: hidden;
  }

  ._header-scroll .icon-menu {
    top: 0px;
  }

  .header__body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0px);
    -ms-transform: translate(-100%, 0px);
    transform: translate(-100%, 0px);
    overflow: auto;
    background-color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0.95;
    padding: 6.25rem 2.8125rem 3.125rem 2.8125rem;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    color: #161616;
  }


  .header__body {
    &.menuOpen {
      -webkit-transform: translate(0%, 0px);
      -ms-transform: translate(0%, 0px);
      transform: translate(0%, 0px);
    }
  }

  .header__delivery {
    display: none;
  }

  .header__bottom {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }

  .menu {
    display: none;
  }

  .menu__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu__item:not(:first-child) {
    margin-left: 0px;
  }

  .menu__item {
    padding: 20px;
    font-size: 20px;
  }

  .menu__link {
    font-weight: 700;
  }

  .secret-footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
    padding: 20px 15px;
  }
}

@media (max-width: 47.99875em) {
  .about-footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }

  .copy-footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
  }

  .social-footer {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 31.25em) {
  .cart {
    width: 100vw;
  }

  .cart__close {
    position: fixed;
    left: auto;
    top: 20px;
    right: 20px;
    background-color: #ff6900;
    border-radius: 10px;
    padding: 5px;
  }

  .item-order__top {
    min-height: 100px;
  }

  .item-order__image-ibg {
    min-width: 90px;
    min-height: 90px;
    padding: 0 0 20% 0;
  }
}

@media (max-width: 29.99875em) {
  .icon-menu {
    -webkit-transform: translate(20%, 0px);
    -ms-transform: translate(20%, 0px);
    transform: translate(20%, 0px);
  }

  .secret-footer__text {
    font-size: 14px;
  }

  .copy-footer__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (any-hover: hover) {
  .button:hover {
    color: rgb(0, 0, 0);
  }

  .button__orange:hover {
    background-color: rgb(232, 97, 0);
    color: #fff;
  }

  .button__check:hover {
    background-color: rgb(255, 210, 179);
    color: rgb(209, 87, 0);
  }

  .dodocoins-header:hover {
    color: rgb(255, 105, 0);
  }

  .dodocoins-header:hover .dodocoins-header__image {
    -webkit-transform: rotate(20deg) scale(1.1);
    -ms-transform: rotate(20deg) scale(1.1);
    transform: rotate(20deg) scale(1.1);
  }

  .dodocoins-header:hover .dodocoins-header__image svg path {
    fill: rgb(255, 105, 0);
  }

  .menu__link:hover {
    color: rgb(255, 105, 0);
  }

  .secret-footer__button:hover {
    background-color: rgb(255, 210, 179);
    color: rgb(209, 87, 0);
  }

  .column-footer__link:hover {
    opacity: 1;
  }

  .download-footer__mail:hover {
    opacity: 1;
  }

  .copy-footer__link:hover {
    opacity: 1;
  }

  .social-footer__item:hover {
    background-color: rgba(196, 196, 196, 0.5);
  }

  .often__overlay:hover img {
    opacity: 1;
  }

  .item-often:hover {
    -webkit-box-shadow: rgba(6, 5, 50, 0.15) 0px 0px 12px -4px;
    box-shadow: rgba(6, 5, 50, 0.15) 0px 0px 12px -4px;
  }

  .item-pizza__image:hover {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }

  .cart__close:hover {
    -webkit-transform: scale(1.1) rotate(180deg);
    -ms-transform: scale(1.1) rotate(180deg);
    transform: scale(1.1) rotate(180deg);
  }

  .quantity__button:hover {
    opacity: 1;
  }

  .dodo-order__info:hover {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .popup-pizza__close:hover {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }

  .description-popup__container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(57, 57, 57, 0.3);
  }

  .description-popup__info:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }

  .add-description-popup__item:hover {
    -webkit-box-shadow: rgba(6, 5, 50, 0.12) 0px 0px 8px;
    box-shadow: rgba(6, 5, 50, 0.12) 0px 0px 8px;
  }
}

